<template>
  <div class="antd-page-content">
    <div class="antd-table-search-wrap" style="position:relative">
    <a-button style="position: absolute; top:10px; right:10px;" type="primary" @click="$router.back()">返回</a-button>
      <div style="margin-bottom: 15px;font-weight: bold">{{courseName}}</div>
      <a-form layout="inline">
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-item label="题目代码">
              <a-input v-model="queryParams.questionCode" placeholder="请输入题目代码"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="题目标题">
              <a-input v-model="queryParams.question" placeholder="请输入题目标题"/>
            </a-form-item>
          </a-col>
          <!-- <a-col :md="6" :sm="24" style="width:200px">
            <a-form-item label="题目类型">
                <a-select placeholder="请选择" v-model="queryParams.questionType"  default-value="0">
                    <a-select-option value="">全部</a-select-option>
                    <a-select-option value="1">单选题</a-select-option>
                    <a-select-option value="2">多选题</a-select-option>
                    <a-select-option value="3">判断题</a-select-option>
                </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :md="6" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>

          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="antd-toolbar">
      <a-space>
      <a-button type="primary" @click="createFormEvt"><a-icon type="plus"></a-icon> 新建</a-button>
      <a-button type="default" @click="uploadEvt">上传试题</a-button>
      <a-button type="default" @click="downloadTplEvt">下载模版</a-button>
      <a-button type="danger"  @click="batchDeleteEvt()"><a-icon type="minus"></a-icon> 批量删除</a-button>
      <a-button type="danger"  @click="emptyDeleteEvt()"><a-icon type="minus"></a-icon> 全部清空</a-button>
      <a-button type="default" @click="ruleFormEvt">测试规则设置</a-button>
      <span style="color:#84c562;font-size:18px;">总题数：{{totalData.totalNums}},单选题:{{totalData.singleNums}},多选题:{{totalData.mutiNums}},判断题:{{totalData.judgNums}}</span>
      </a-space>
      <rule-form ref="ruleForm" :visible="ruleFormVisible" @refreshDataList="$refs.table.refresh(true)"/>
      <add-form ref="addForm" :visible="addFormVisible" @refreshDataList="$refs.table.refresh(true)"/>
      <update-form ref="updateForm" :visible="updateFormVisible" @refreshDataList="$refs.table.refresh(true)"/>
      <upload-form ref="uploadForm" :visible="uploadFormVisible" @refreshDataList="$refs.table.refresh(true)"/>
    </div>
    <div class="antd-table-wrap">
      <s-table
        ref="table"
        rowKey="id"
        :columns='columns'
        :data='loadData'
        :alert="alert"
        :rowSelection="rowSelection"
        :pageNum="pageNum"
        :pagination="{pageSizeOptions:['10', '20','50', '100', '150']}"
      >
        <template slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="createTime" slot-scope="text">
          {{text|createTimeFilter}}
        </template>
        <span slot="questionType" slot-scope="text, record">
            <a-tag color="red" v-if="record.questionType == 1">单选题</a-tag>
            <a-tag color="green" v-if="record.questionType == 2">多选题</a-tag>
            <a-tag color="cyan" v-if="record.questionType == 3">判断题</a-tag>
        </span>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:void(0)" class="table-operation-action" @click="updateFormEvt(record)">编辑</a>
          <a-popconfirm
            title="确定删除此数据么？"
            ok-text="确定"
            cancel-text="取消"
            placement="topLeft"
            @confirm="deleteEvt(record)"
          >
            <a-icon slot="icon" type="question-circle-o" style="color: red" />
            <a href="javascript:void(0)" class="table-operation-action">删除</a>
          </a-popconfirm>
        </template>
      </s-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import STable from '@/components/Table'
import {getQuestionList,delQuestion,emptyDeleteEvt,downQuestionTemplate} from '@/httpsAPI/course/index'
import RuleForm from './rule-setting'
import AddForm from './add'
import UpdateForm from './update'
import UploadForm from './upload'

const columns=[
  {title:"序号",dataIndex:"serial",scopedSlots: { customRender: 'serial' },width:80},
  {title:"题目代码",dataIndex:"questionCode"},
  {title:"题目",dataIndex:"question"},
  {title:"题目类型",dataIndex:"questionType",scopedSlots:{customRender:"questionType"}},
  {title:"创建时间",dataIndex:"createTime",align:'center',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    scopedSlots:{customRender:"createTime"}},
  {title:"操作",dataIndex:"action",align:'center',scopedSlots:{customRender:"action"},width:"158px"}
]
export default {
  data(){
    return{
      courseEmptyNo:'',
      visible: false,
      confirmLoading: false,
      queryParams:{},
      columns,
      pageNum: 1,
      ruleFormVisible: false,
      addFormVisible: false,
      updateFormVisible: false,
      uploadFormVisible: false,
      courseId: 0,
      courseNo: '',
      courseName: '',
      courseTypes: 10000,
      totalData: {
        totalNums:0,
        singleNums:0,
        mutiNums:0,
        judgNums:0
      },
      alert:{
        show:true,
        clear:()=>{this.selectedRowKeys = []}
      },
      loadData:parameter => {
        this.pageNum = parameter.pageNo
        this.queryParams.courseId = this.courseId
        return getQuestionList(Object.assign(parameter,this.queryParams)).then(res=>{
          console.log(res)
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  created () {
    console.log(this.$route.query.courseNo,"this.$route.query.courseNo")
    this.courseNo = this.$route.query.courseNo
    this.courseId = this.$route.query.courseId
    // this.loadCourseData()
  },
  computed:{
    rowSelection(){
      return {
        selectedRowKeys:this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  components:{
    RuleForm,
    AddForm,
    UpdateForm,
    UploadForm
  },
  filters: {
    createTimeFilter (value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
    }
  },
  methods:{
    // 新建
    createFormEvt(record) {
        this.addFormVisible = true
        this.$nextTick(() => {
          console.log(this.courseNo,"this.courseNo")
          this.$refs.addForm.init(this.courseId, this.courseNo)
        })
    },
    // 修改
    updateFormEvt(record) {
        this.updateFormVisible = true
        this.$nextTick(() => {
          this.$refs.updateForm.init(record.id,record.courseNo)
        })
    },
    // 删除
    deleteEvt(item) {
        const that = this
        delQuestion([item.id]).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
                }
            })
    },
    // 批量删除
    batchDeleteEvt() {
      if(this.selectedRowKeys.length==0) {
        this.$message.warning({
              content: '请选择一条数据进行操作！',
              duration: 1
          })
        return
      }
      const that = this
      this.$confirm({
        title: '系统提示',
        content: '确认删除选中数据么?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          delQuestion(that.selectedRowKeys).then(res=>{
              if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
              } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
              }
          })
        }
      })
    },
    //试题全部清空
    emptyDeleteEvt() {
      const that = this
      this.$confirm({
        title: '系统提示',
        content: '确认删除全部数据么?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          emptyDeleteEvt(that.courseNo).then(res=>{
              if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
              } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
              }
          })
        }
      })
    },
    // 上传试题
    uploadEvt() {
      this.uploadFormVisible = true
      this.$nextTick(() => {
        this.$refs.uploadForm.init(this.courseId, this.courseNo)
      })
    },
    // 下载模板
    downloadTplEvt() {
      downQuestionTemplate().then(res=>{
        console.log('下载完成')
      })
    },
    // 设置规则
    ruleFormEvt() {
        this.ruleFormVisible = true
        this.$nextTick(() => {
          this.$refs.ruleForm.init(this.courseNo,this.courseId, this.courseTypes, this.totalData)
        })
    },
    // 重置搜索form
    resetQueryForm() {
        this.queryParams = {}
        this.$refs.table.refresh(true)
    },
    onSelectChange(selectedRowKeys, selectedRows){
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
<style lang="less" scoped>
.ad-pic{
  max-width: 120px;
  display: inline-block;
  height: 64px;
}
</style>