<template>
  <a-modal
    title="测试规则设置"
    :width="700"
    :visible="visible"
    :confirm-loading="confirmLoading"
    destroyOnClose
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="测试数量">
          <a-input v-decorator="['itemNum', {rules: [{required: true, message: '请输入测试数量'}, { validator: validateNumber, trigger: 'blur' }]}]" placeholder="请输入测试数量" />
        </a-form-item>
        <a-form-item label="单选题占比(%)">
          <a-input v-decorator="['type2', {rules: [{required: true, message: '请输入单选题占比'}, { validator: validateNumber, trigger: 'blur' }]}]" placeholder="请输入单选题占比" />
        </a-form-item>
        <a-form-item label="多选题占比(%)">
          <a-input v-decorator="['type3', {rules: [{required: true, message: '请输入多选题占比'}, { validator: validateNumber, trigger: 'blur' }]}]" placeholder="请输入多选题占比" />
        </a-form-item>
        <a-form-item label="判断题占比(%)">
          <a-input v-decorator="['type1', {rules: [{required: true, message: '请输入判断题占比'}, { validator: validateNumber, trigger: 'blur' }]}]" placeholder="请输入判断题占比" />
        </a-form-item>
        <a-form-item label="题测时间(分钟)">
          <a-input v-decorator="['examTime', {rules: [{required: true, message: '请输入题测时间'}, { validator: validateNumber, trigger: 'blur' }]}]" placeholder="请输入题测时间" />
        </a-form-item>
      </a-form>
      <span style="color:#84c562;font-size:18px;">总题数：{{totalData.totalNums}},单选题:{{totalData.singleNums}},多选题:{{totalData.mutiNums}},判断题:{{totalData.judgNums}}</span>
    </a-spin>

    <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
  </a-modal>
</template>
<script>
import {getQuestionRule,saveQuestionRule} from '@/httpsAPI/course/index'
export default {
  data () {
    return {
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      courseNo: 0,
      courseId:'',
      id: 0,
      courseTypes: 100000,
      totalData: {}
    }
  },
  methods: {
    // 验证数字输入
    async validateNumber (rule, value, callback) {
      if (typeof (value) === 'undefined' || value === '') {
        callback()
      } else {
        if (!/^[1-9][0-9]*(\.[0-9]{1,2})?$/.test(value) && value!='0') {
         callback(new Error('只能输入数字，且不能超过2位小数'))
        }
        callback()
      }
    },
    dataFormSubmit(e) {
            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }

                if((parseFloat(values['type1']) + parseFloat(values['type2']) + parseFloat(values['type3'])) !== 100) {
                  this.$message.warning({
                        content: '规则占比总数须等于100%',
                        duration: 1
                    })
                  return
                }

                if(this.id!=0) {
                  values['id'] = this.id
                }

                this.confirmLoading = true
                values['courseNo'] = this.courseNo
                console.log('Received values of form: ', values);
                saveQuestionRule(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                  this.confirmLoading = false  
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (courseNo,courseId, courseTypes, totalData) {
            this.visible = true
            this.courseNo = courseNo
            this.courseId = courseId
            this.courseTypes = courseTypes
            this.totalData = totalData

            this.$nextTick(() => { 
                this.form.resetFields();
                getQuestionRule(courseId).then(res=>{
                  if(res.data) {
                    this.id = res.data.id
                    this.form.setFieldsValue({
                      itemNum: res.data.itemNum,
                      type1: res.data.type1,
                      type2: res.data.type2,
                      type3: res.data.type3,
                      examTime: res.data.examTime
                    })
                  } else {
                    this.form.setFieldsValue({
                      itemNum: 30,
                      type1: 45,
                      type2: 45,
                      type3: 10,
                      examTime: 30
                    })
                  }
                })
            })
          }
  }
}
</script>